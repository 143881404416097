<template>
  <div class="home">
    <ExamCreator />
  </div>
</template>

<script>
// @ is an alias to /src
import ExamCreator from "@/components/exam/examCreate/ExamCreator";

export default {
  name: "ExamsCreateView",
  components: {
    ExamCreator,
  },
};
</script>